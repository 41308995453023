import { domReady } from "@roots/sage/client";
import "jquery";
import "bootstrap";
// import AOS from 'aos';
//var AOS = require('aos');
import Isotope from "isotope-layout";

// import Locomotive
import LocomotiveScroll from "locomotive-scroll";
import imagesLoaded from "imagesloaded";
/**
 * app.main
 */
const main = async (err) => {
  if (err) {
    // handle hmr errors
    console.error(err, 'hi');
  }

  // application code
};


/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
// domReady(main);

import.meta.webpackHot?.accept(main);


// jQuery(document).ready(function () {
document.addEventListener("DOMContentLoaded", function () {
  const verified = localStorage.getItem("verified");
  const ageModal = document.getElementById("ageModal");
  var returnUrl = window.history.href;
  if (!verified) {
    jQuery(ageModal).modal("show");
  }
  jQuery("#acceptAge").click(function () {
    localStorage.setItem("verified", "true");
    jQuery(ageModal).modal("hide");
  });
  jQuery("#dismissAge").click(function () {
    if ((returnUrl = "undefined")) {
      window.location.href = "https://www.nix18.nl/";
    } else {
      window.location.href = returnUrl;
    }
  });

  // Locomotive scroll
  const scroll = new LocomotiveScroll({
    el: document.querySelector("[data-scroll-container]"),
    smooth: true,
    mobile: {
      smooth: false
    },
  });

  jQuery(".go-down").click(function () {
    const target = document.querySelector(".bg-off-white");
    scroll.scrollTo(target);
  });

  //   // Navbar fixed on scroll
  //   const navbar = document.getElementById("header");
  //   // OnScroll event handler
  //   const onScroll = () => {
  //     // Get scroll value
  //     const scrollPosition = document.documentElement.scrollTop;
  //     // If scroll value is more than 0 - add class
  //     if (scrollPosition > 130) {
  //       navbar.classList.add("scrolled");
  //     } else {
  //       navbar.classList.remove("scrolled");
  //     }
  //   };

  let prevScrollPos = scroll.scroll.instance.scroll.y;

  scroll.on("scroll", function () {
    const header = document.querySelector("header");
    const currentScrollPos = scroll.scroll.instance.scroll.y;

    if (prevScrollPos > 130) {
      header.classList.add("scrolled");
    } else {
      header.classList.remove("scrolled");
    }

    prevScrollPos = currentScrollPos;
  });



  // Use the function
  //   window.addEventListener("scrollPosition", onScroll);

  // Isotope
  var grid = document.querySelector(".grid-producten");
  if (grid) {
    var iso = new Isotope(grid, {
      itemSelector: ".item",
      layoutMode: "fitRows",
    });
    jQuery(".filters-button-group button").click(function () {
      // To filter your isotope.
      var data = jQuery(this).attr("data-filter");
      iso.arrange({
        filter: data, // All grid items
      });
    });
  }

  // reset modal position
  jQuery(".modal").appendTo("body");

  // Get a reference to the <path>
  var path = document.querySelector("#path-line-svg");
  if (path) {
    // Get length of path... ~577px in this case
    var pathLength = path.getTotalLength();
    // Make very long dashes (the length of the path itself)
    path.style.strokeDasharray = pathLength + " " + pathLength;

    // Offset the dashes so the it appears hidden entirely
    path.style.strokeDashoffset = pathLength;

    // Jake Archibald says so
    // https://jakearchibald.com/2013/animated-line-drawing-svg/
    path.getBoundingClientRect();

    scroll.on("scroll", ({ limit, scroll }) => {
      const progress = scroll.y / limit.y;

      // Length to offset the dashes
      var drawLength = pathLength * progress;
      // console.log(drawLength);

      // Draw in reverse
      path.style.strokeDashoffset = pathLength - drawLength;

      // When complete, remove the dash array, otherwise shape isn't quite sharp
      // Accounts for fuzzy math
      if (progress >= 0.99) {
        path.style.strokeDasharray = "none";
      } else {
        path.style.strokeDasharray = pathLength + " " + pathLength;
      }
    });
  }

  jQuery("body").imagesLoaded(function () {
    // Preloader
    // jQuery('.loader-mask').addClass('hide');
    jQuery('.loader').fadeOut();
    jQuery('.loader-mask').fadeOut('slow');
    // images have loaded
    console.log("images have loaded");
    scroll.update();
  });
});


